<template>
	<div id="team" class="lms-tab-view">
		<nav-bar class="team-mode" :nav-settings="navSettings"></nav-bar>
		<section id="lumos-meet-team" class="lumos-meet-team lms-section">
			<div id="team_page_container" class="lms-content-frame vertical">
				<div class="lms-section-head">
					<h2 id="team_title" class="title" style="color:#313131" v-html="getContent.team.title[getLanguage]">
					</h2>
				</div>
				<div class="chart">
					<div class="header_image_container">
						<img class="header_image" src="../assets/team/2023S/DSC02216.jpg" alt="group photo" />
					</div>

					<div v-if="this.loadStatus" id="apex-container1">
						<Loader height="400px"></Loader>
					</div>
					<div v-else id="apex-container1">
						<h3 v-html="getContent.apexTitle.title_1[getLanguage]" style="text-align:center;"></h3><br>
						<apexchart type="donut" class="apexchart" :options="degreeChartOptions" :series="degreeSeries">
						</apexchart>
					</div>

					<div id="team_description_container">
						<span id="team_description" v-html="getContent.team.teamdescription[getLanguage]"></span>
					</div>

					<div v-if="this.loadStatus" id="apex-container2">
						<Loader height="400px"></Loader>
					</div>
					<div v-else id="apex-container2">
						<h3 v-html="getContent.apexTitle.title_2[getLanguage]" style="text-align:center;"></h3><br>
						<apexchart type="donut" class="apexchart" :options="universityChartOptions" :series="universitySeries">
						</apexchart>
					</div>
				</div>

				<Splide :options="{ padding: '1rem', rewind: true }" aria-label="Departments">

					<SplideSlide v-for="department in getContent.departments[getLanguage]" v-bind:key="department.id">
						<TeamSlide :department=department></TeamSlide>
					</SplideSlide>
				</Splide>


			</div>
		</section>


		<Footer :footer-settings="navSettings"></Footer>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import VueApexCharts from "vue-apexcharts";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
import Loader from "@/components/Loader.vue";

import TeamSlide from "@/components/TeamSlide.vue";
import * as ManualCookies from '@/assets/js/cookie-functions.js'


export default {
	components: {
		NavBar,
		Footer,
		Splide,
		SplideSlide,
		VueApexCharts,
		TeamSlide,
		Loader
	},

	metaInfo() {
		return {
			title: 'Lumos - Meet the Team',
			meta: [
				{
					name: 'description',
					content:
						'Lumos ist die erste studentische Data Science Beratung in Österreich. Gemeinsam mit unserem Team aus ' +
						'talentierten Studierenden unterstützen wir Unternehmen bei der Anwendung neuer Technologien.'
				},
			]
		}
	},
	data() {
		return {
			loadStatus: true,

			pastSection: null,
			base: null,
			dataPerson: null,
			showSplide: false,
			splideOptions: {
				width: "100%",
				perPage: 1,
				autoplay: false,
				intervall: 3000,
				speed: 1000,
				pauseOnHover: false,
				drag: true,
			},

			degreeSeries: [],
			universitySeries: [],

			degreeChartOptions: {
				// colors:['#af3a54','#DE6D83','#f4bbc4','#D8D8D8','#A6A6A6'],
				theme: {
					monochrome: {
						enabled: true,
						color: '#af3a54',
						shadeTo: 'light',
						shadeIntensity: 0.98
					}
				},
				chart: {
					type: 'donut',
					foreColor: '#888087',
					fontFamily: "Open Sans, Nunito, sans-serif",
				},
				states: {
					hover: {
						filter: {
							type: 'none',
						}
					},
				},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '55%',
						}
					}
				},
				stroke: {
					show: false,
					width: 0
				},
				tooltip: {
					enabled: false,
					onDatasetHover: {
						highlightDataSeries: false,
					},
				},
				labels: [],
				responsive: [{
					breakpoint: 550,
					options: {
						chart: {
							width: "100%",
						},
						legend: {
							position: 'bottom',
							fontSize: "11px"
						},
						dataLabels: {
							style: {
								fontSize: '12px',
							},
						}
					}
				}],
				legend: {
					color: "#313131",
					fontSize: '14px',
					position: 'right',
					markers: {
						height: '14px',
						width: '14px',
					},
					itemMargin: {
						horizontal: 10,
						vertical: 5,
					},
					onItemClick: {
						toggleDataSeries: false,
					},
					onItemHover: {
						highlightDataSeries: false,
						fontSize: '14px',
					},
				},
				dataLabels: {

					style: {
						fontSize: '14px',
						fontFamily: "Open Sans, Nunito, sans-serif",
						fontWeight: 'bold',
						colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
					},
					textAnchor: 'middle',
					dropShadow: {
						enabled: true,
						top: 0.5,
						left: 0.5,
						blur: 1,
						color: '#313131',
					}
				}
			},

			universityChartOptions: {
				// colors:['#6d87bb','#97aeeb','#d7e3ff','#7dbaef','#344f6a'],

				theme: {
					monochrome: {
						enabled: true,
						color: '#11385f',
						shadeTo: 'light',
						shadeIntensity: 0.98
					}
				},
				expandOnClick: false,
				chart: {
					type: 'donut',
					foreColor: '#888087',
					fontFamily: "Open Sans, Nunito, sans-serif",
					expandOnClick: false,


				},

				states: {
					hover: {
						filter: {
							type: 'none',
						}
					},
				},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '55%',
						}
					},
				},
				legend: {

					fontSize: '14px',
					position: 'right',
					markers: {
						height: '14px',
						width: '14px',
					},
					itemMargin: {
						horizontal: 10,
						vertical: 5,
					},
					onItemClick: {
						toggleDataSeries: false,
					},
					onItemHover: {
						highlightDataSeries: false,
						fontSize: '14px',
					},
				},
				stroke: {
					show: false,
					width: 0
				},
				tooltip: {
					enabled: false,
					onDatasetHover: {
						highlightDataSeries: false,
					},
				},
				labels: [],
				responsive: [{
					breakpoint: 550,
					options: {
						chart: {
							width: 350
						},
						legend: {
							position: 'bottom',
							fontSize: "11px"
						},
						dataLabels: {
							style: {
								fontSize: '12px',
							},
						}
					}
				}],
				dataLabels: {

					style: {
						fontSize: '14px',
						fontFamily: "Open Sans, Nunito, sans-serif",
						fontWeight: 'bold',
						colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
					},
					textAnchor: 'middle',
					dropShadow: {
						enabled: true,
						top: 0.5,
						left: 0.5,
						blur: 1,
						color: '#313131',
					}
				}
			},
		};
	},
	computed: {
		getLanguage() {
			return this.$store.state.language;
		},
		getContent: function () {
			return this.$store.state;
		},
		navSettings() {
			return [
				{
					title: this.getContent.navTitles[0][this.getLanguage],
					path: "/",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Zm0,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm0,8a1,1,0,0,1,.993.883L12,11v4a1,1,0,0,1-1.993.117L10,15V11A1,1,0,0,1,11,10Zm.01-4a1,1,0,0,1,.117,1.993L11,8a1,1,0,0,1-.117-1.993Z"/></svg>',
					scrollSettings: {
						el: ".why-lumos",
						offset: -120,
					},
				},
				{
					title: this.getContent.navTitles[1][this.getLanguage],
					path: "/",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M19,11a1,1,0,0,1,1,1h0v7a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1h0V12a1,1,0,0,1,1-1h7ZM8,11a1,1,0,0,1,1,1H9v7a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1H0V12a1,1,0,0,1,1-1H8Zm10,2H13v5h5ZM7,13H2v5H7ZM8,0A1,1,0,0,1,9,1H9V8A1,1,0,0,1,8,9H1A1,1,0,0,1,0,8H0V1A1,1,0,0,1,1,0H8ZM19,0a1,1,0,0,1,1,1h0V8a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1h0V1a1,1,0,0,1,1-1h7ZM7,2H2V7H7ZM18,2H13V7h5Z"/></svg>',
					scrollSettings: {
						el: ".our-services",
						offset: 50,
					},
				},
				{
					title: this.getContent.navTitles[2][this.getLanguage],
					path: "/apply",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20"><path d="M13,12a5,5,0,0,1,5,4.783L18,17v2a1,1,0,0,1-1.993.117L16,19V17a3,3,0,0,0-2.824-2.995L13,14H5a3,3,0,0,0-2.995,2.824L2,17v2a1,1,0,0,1-1.993.117L0,19V17a5,5,0,0,1,4.783-5L5,12Zm7.25.162a5,5,0,0,1,3.745,4.611L24,17v2a1,1,0,0,1-1.993.117L22,19V17a3,3,0,0,0-2.25-2.9,1,1,0,1,1,.5-1.936ZM9,0A5,5,0,1,1,4,5,5,5,0,0,1,9,0Zm7.248.161a5,5,0,0,1,0,9.688,1,1,0,0,1-.5-1.937,3,3,0,0,0,0-5.812,1,1,0,0,1,.5-1.938ZM9,2a3,3,0,1,0,3,3A3,3,0,0,0,9,2Z"/></svg>',
					scrollSettings: {},
				},
				{
					title: this.getContent.navTitles[3][this.getLanguage],
					path: "/blog",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.999" viewBox="0 0 22 19.999"><path d="M7,0a4.992,4.992,0,0,1,4,2,4.991,4.991,0,0,1,4-2h6a1,1,0,0,1,1,1h0V16a1,1,0,0,1-1,1H14a2,2,0,0,0-2,2,.949.949,0,0,1-.845.989l-.1.01h-.1A.957.957,0,0,1,10,19h0l-.005-.149A2,2,0,0,0,8,17H1a1,1,0,0,1-1-1H0V1A1,1,0,0,1,1,0H7ZM7,2H2V15H8a3.982,3.982,0,0,1,1.855.455h0l.145.081V5h0l-.005-.176A3,3,0,0,0,7.176,2.005h0ZM20,2H15a3,3,0,0,0-3,3h0V15.535l.145-.08A3.977,3.977,0,0,1,13.8,15h0l.2,0h6Z"/></svg>',
					scrollSettings: {},
				},

				{
					title: this.getContent.navTitles[4][this.getLanguage],
					path: "/team",
					icon:
						'<svg id="Layer_1" viewBox="16 24 60 45" width="25" height="26" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><g><path d="M46.252,22.97c-6.738,0-12.219,5.484-12.219,12.223c0,2.725,0.907,5.236,2.42,7.27c-2.98,2.084-4.777,5.191-4.777,8.521   v17.369h3.4V50.983c0-2.371,1.436-4.619,3.804-6.068c2.052,1.561,4.601,2.498,7.372,2.498c2.806,0,5.385-0.959,7.451-2.557   c2.427,1.449,3.897,3.719,3.897,6.127v17.369H61V50.983c0-3.383-1.839-6.516-4.89-8.598c1.479-2.021,2.363-4.504,2.363-7.193   C58.474,28.454,52.991,22.97,46.252,22.97z M46.252,44.015c-4.863,0-8.818-3.959-8.818-8.822c0-4.865,3.955-8.822,8.818-8.822   c4.864,0,8.822,3.957,8.822,8.822C55.074,40.056,51.116,44.015,46.252,44.015z"/><path d="M18.976,48.233c0-1.043,0.596-2.037,1.579-2.768c1.26,0.838,2.768,1.33,4.391,1.33c4.394,0,7.969-3.572,7.969-7.965   c0-4.395-3.575-7.969-7.969-7.969c-4.393,0-7.965,3.574-7.965,7.969c0,1.529,0.453,2.943,1.202,4.156   c-1.642,1.359-2.607,3.242-2.607,5.246v10.084h3.4V48.233z M24.945,34.263c2.52,0,4.568,2.049,4.568,4.568   c0,2.516-2.049,4.564-4.568,4.564c-2.518,0-4.565-2.049-4.565-4.564C20.38,36.312,22.428,34.263,24.945,34.263z"/><path d="M74.422,42.931c0.729-1.201,1.172-2.596,1.172-4.1c0-4.395-3.574-7.969-7.966-7.969c-4.394,0-7.969,3.574-7.969,7.969   c0,4.393,3.575,7.965,7.969,7.965c1.646,0,3.178-0.502,4.448-1.361c1.01,0.73,1.621,1.738,1.621,2.799v10.084h3.4V48.233   C77.098,46.194,76.106,44.288,74.422,42.931z M67.628,43.396c-2.52,0-4.569-2.049-4.569-4.564c0-2.52,2.05-4.568,4.569-4.568   c2.518,0,4.565,2.049,4.565,4.568C72.193,41.347,70.146,43.396,67.628,43.396z"/></g></svg>',

					scrollSettings: {},
				},
				{
					title: this.getContent.navTitles[5][this.getLanguage],
					path: "/events",
					icon:
						'<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1V1.89474H17.6315V1C17.6315 0.447715 18.0792 0 18.6315 0C19.1838 0 19.6315 0.447715 19.6315 1V1.93424C22.0948 2.2447 24 4.34723 24 6.89474V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V6.89473C0 4.13331 2.23858 1.89474 5 1.89474V1ZM19 3.89474H5C3.34314 3.89474 2 5.23788 2 6.89473V8.10526H22V6.89474C22 5.23788 20.6569 3.89474 19 3.89474ZM2 19V10.1053H22V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19Z"/></svg>',

					scrollSettings: {},
				},
			];
		},

	},
	methods: {
		toggleSplide(SectionId) {
			// get the section
			var Vorstand = document.getElementById('VorstandSection');
			var Sales = document.getElementById('SalesSection');
			var Marketing = document.getElementById('MarketingSection');
			var HR = document.getElementById('HRSection');
			var Academy = document.getElementById('AcademySection');
			var ITOPS = document.getElementById('ITOPSSection');
			var FINANCE = document.getElementById('FINANCESection');
			var ADVISORY = document.getElementById('ADVISORYSection');
			var ALL = document.getElementById('AllSection');

			console.log(SectionId);
			Vorstand.style.display = 'none';
			Sales.style.display = 'none';
			Marketing.style.display = 'none';
			HR.style.display = 'none';
			Academy.style.display = 'none';
			ITOPS.style.display = 'none';
			FINANCE.style.display = 'none';
			ADVISORY.style.display = 'none';
			ALL.style.display = 'none';

			// get the current value of the clock's display property
			var mySection = document.getElementById(SectionId);
			var displaySetting = mySection.style.display;
			// now toggle the section and the button text, depending on current state
			if (displaySetting === 'block') {
				// section is visible. hide it
				mySection.style.display = 'none';
			} else {
				// section is hidden. show it
				mySection.style.display = 'block';
			}
		},
		chartsLoadingStatus: async function () {
			while (this.getContent.team.all_members_cont[this.getLanguage].length === 0) {
				await new Promise(resolve => setTimeout(resolve, 100)); // Delay and then check again
			}

			this.populateCharts();

			this.loadStatus = false;
		},
		populateCharts: function () {
			const members = this.getContent.team.all_members_cont[this.getLanguage];

			const degreeCounts = {};
			const universityCounts = {};

			members.forEach(item => {
				item.degrees.forEach(degree => {
					degreeCounts[degree] = (degreeCounts[degree] || 0) + 1;
				});

				item.university.forEach(university => {
					universityCounts[university] = (universityCounts[university] || 0) + 1;
				});
			});

			const sortedDegreeEntries = Object.entries(degreeCounts).sort((a, b) => b[1] - a[1]);
			const sortedUniversityEntries = Object.entries(universityCounts).sort((a, b) => b[1] - a[1]);

			const threshold = 1;
			const createOtherDictCategory = (counts) => {
				const result = {};
				var other = 0

				Object.entries(counts).forEach(([key, value]) => {
					if (value > threshold) {
						result[key] = value;
					} else {
						other += value;
					}
				});
				if (other > 0) {
					result['Other'] = other;
				}

				Object.keys(result).forEach(key => {
					if (result[key] <= threshold && key !== 'Other') {
						delete result[key];
					}
				});

				return result;
			};

			const sortedDegreeCounts = createOtherDictCategory(Object.fromEntries(sortedDegreeEntries));
			const sortedUniversityCounts = createOtherDictCategory(Object.fromEntries(sortedUniversityEntries));

			const degreeNames = Object.keys(sortedDegreeCounts);
			const degreeFrequencies = Object.values(sortedDegreeCounts);

			const universityNames = Object.keys(sortedUniversityCounts);
			const universityFrequencies = Object.values(sortedUniversityCounts);

			this.degreeSeries = degreeFrequencies;
			this.universitySeries = universityFrequencies;

			this.degreeChartOptions.labels = degreeNames;
			this.universityChartOptions.labels = universityNames;
		}
	},
	mounted() {
		if (localStorage.language) {
			this.$store.commit("switchLanguage", localStorage.language);
		}

		this.$store.dispatch('fetchMembers');
		this.chartsLoadingStatus();

		//Statistic
		ManualCookies.sendCookies("Team");
	},
};

</script>

<style lang="scss" scoped>
.splide {
	padding: 0;
}

.lumos-meet-team {
	position: relative;
	padding-top: 100px;

}

// hr {
//   margin-top: 100px;
//   margin-bottom: 0px;
//   color:#EBEAEC;
//   width: 100%;
// }
////////////////////////
//// Changes Magnus ////
////////////////////////


#team_page_container {
	// padding-left: 30px;
	margin-left: auto;
	margin-right: auto;
	// background-image: url("../assets/team/team-page-background-svg.svg");
	background-size: cover;
	background-position: center center;
}

#team_title {
	margin-top: 25px;
}

.chart {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-content: center;

	span {
		font-family: 'Open Sans', sans-serif;
		font-size: 14px;
		float: left;
	}
}

.header_image_container {
	order: 1;
	// height:800px;
	// width:600px;
	width: auto;
	margin-left: 0;
	margin-top: 15px;
	margin-bottom: 30px;

	.header_image {
		// border-radius: 10px;
		// box-shadow: 3px 3px 5px #1b1b1b;
		-webkit-box-shadow: 0 5px 17px -10px #000000;
		box-shadow: 0 5px 17px -10px #000000;
		width: auto;
		// width: 800px;
		height: 380px;
		// width: auto;
		// height: 320px;
		// display: block;
		margin: 10px auto 30px 0;
	}
}


#team_description_container {
	order: 3;
	margin-top: 0;
	width: 600px;
	text-align: justify;
	margin-left: 0;
	margin-bottom: 50px;
}

#team_description {
	color: #888087;
	font-family: $open-sans;
	font-size: 18px;
	line-height: 24px;
	margin-top: 0;
}

// .apexchart {
//   width: 500;
// }

#apex-container1 {
	order: 2;
	width: 500px;
	border-radius: 0;
	margin-top: 30px;
	margin-left: 70px;
	// margin-bottom: 30px;
}

#apex-container2 {
	order: 4;
	padding: 10px;
	width: 500px;
	border-radius: 0;
	margin: -100px -100px 20px 150px;
}

////////////////////////
////////////////////////
////////////////////////


.landing {
	min-height: calc(104vh);
	overflow-x: hidden;

	.content {
		width: 50%;

		.lms-texture {
			height: 60px;
			margin: 0 0 20px 0;
		}
	}

	h1 {
		font-size: 45px;
		font-weight: 400;
		font-family: $avenir;
	}

	p {
		font-size: 18px;
		line-height: 30px;
		margin: 20px 0 35px 0;
		opacity: 0.6;
	}

	.lms-main-button:first-of-type {
		margin: 0 20px 0 0;
	}


	.scroll-down {
		position: absolute;
		background: none;
		border: none;
		outline: none;
		cursor: pointer;
		bottom: 60px;
		transition: 0.3s ease-in-out;

		img {
			width: 35px;
		}

		&:hover {
			transform: scale(1.1);
		}
	}

}

.buttons-splide {

	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: row;
	padding: 10px;
	margin-bottom: 30px;

	.lms-main-button {
		font-size: 18px;
	}

	.lms-main-button:focus {
		background-color: #ff216b;
		border-color: #ff216b;
	}

	.department-wrapper {
		background-color: #e95a78;
		border-radius: 10px;
		border: 2px solid #cc4663;
		padding: 5px;
		color: #f5f5f5;
		width: auto;
		font-size: 24px;
		transition: all .5s;
	}

	.department-wrapper:hover {
		background-color: #fc6082;
	}

	.department-button {
		color: #f5f5f5;
		width: auto;
	}
}

.departmentTitle {
	text-align: center;
	font-size: 36px;
	padding-bottom: 10px;
}

.departmentDescription {
	text-align: justify;
	font-size: 18px;
	// background-color: white;
	// border: 1px solid $border-grey;
	// box-shadow: 0px 0px 6px lightgray;
	// border-radius: 20px;
	padding: 10px;
}

.lumos-meet-team {
	background-color: white;
	// background-image: url("../assets/team/team-page-background-svg2.svg");
	background-size: cover;
	background-position: center center;
	padding-bottom: 30px;

	.lms-content-frame {
		padding: 0;
	}

	.splide__arrow svg,
	.splide__arrow:hover svg {
		fill: #344f6a83;

		&:hover {
			fill: #ef5175;
		}
	}

	.splide__arrow {
		top: 180px;
	}

	.splide__pagination__page:hover,
	.splide__pagination__page.is-active {
		background: #344f6a83;

	}

	.lumos-team-slider {

		.content {
			width: 1050px;
			height: 80%;
			margin: 10px;
			padding: 15px 15px 30px;
			background-color: white;
			border: 1px solid $border-grey;
			box-shadow: 0 0 6px lightgray;
			border-radius: 20px;

			div {
				width: 100%;
				height: 100%;

				div {
					display: flex;
					flex-direction: row;
				}


				// img{
				//   border-radius: 15px;
				//   display: inline-block;
				//   position:relative;
				//   max-width:20%;
				//   max-height: 80%;
				//   margin:5px;
				//   border-color:#fc6082;
				//   border-style:solid;
				// }

				div {
					width: 100%;

					#aboutmediv {
						max-width: 100%;
					}

					.information {
						display: flex;
						max-width: 20%;
						max-height: 80%;
						margin: 5px;
						flex-direction: column;
						border-radius: 15px;
						background: #fff9fb;
						border-color: #fc6082;
						border-style: solid;
						border-width: 2px;

						.infotitle {
							margin-left: 10px;
							margin-top: 5px;
						}

						img {
							border-radius: 13px;
							height: 100%;
							max-width: 100%;
							object-fit: fill;

						}
					}

					.title-right {
						margin-top: 15px;
						margin-left: 12px;
						font-family: $avenir;
						font-size: 17px;
						width: 85%;
					}


				}
			}
		}

		.person {
			font-size: 24px;
			font-family: $avenir;
			padding: 0 0 5px 0;

			.lastName {
				color: $lumos-pink;
			}

		}

	}


}

.contact {
	background: #f5f6f8;
	padding: 300px 0;

	.lms-content-frame {
		justify-content: space-between;
	}

	.lms-description-box {
		margin: 0 10% 0 0;

		.content {
			width: 100%;
		}
	}

	.email-link {
		display: flex;
		align-items: center;

		svg {
			height: 16px;
			margin: 0 12px 0 0;
		}

		a {
			color: $lumos-pink;
			text-decoration: none;
			font-family: "Open Sans", sans-serif;
			font-weight: 500;
		}
	}

	.contact-form {
		display: flex;
		flex-direction: column;
		background-color: white;
		border: 1px solid $border-grey;
		border-radius: 5px;
		box-sizing: border-box;
		padding: 40px;
		margin: 0 0 0 auto;
	}
}

/* // this is broken idk y
.row {
	display: flexbox;
	flex-flow: row wrap;
}
*/

// Padding optimization //
@media screen and (max-width: 1271px) {
	#lumos-meet-team {
		background-color: white;
		// background-image: url("../assets/team/team-page-background-svg3.svg");
		background-size: cover;
		background-position: center center;
		padding-left: 25px;
		padding-right: 25px;
	}


	.chart {
		// width: 500px;
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: center;
		//margin-bottom: 20px;
		//margin: auto;
	}

	/*
	#team_page_container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
*/

	#team_description_container {
		order: 2;
		width: 500px;
		text-align: justify;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0;
	}

	#team_description {
		color: #888087;
		font-size: 18px;
		line-height: 24px;
		margin-top: 0;
		margin-left: 0;
		margin-bottom: 0;
	}

	#apex-container1 {
		order: 3;
		margin-top: 70px;
		margin-left: 0;
		margin-bottom: 0;
	}

	#apex-container2 {
		order: 4;
		padding: 0;
		margin: 20px 0 20px 3px;
	}


	#team_title {
		font-size: 35px;
	}


	.container-all {
		display: inline-flex;
		flex-wrap: wrap;
		align-content: space-between;
		width: 240px;
		height: 250px;
		margin: 20px;
	}


	.wrapper-all {
		opacity: 1;
		width: 240px;
		border-radius: 1em;
		border: solid;
		border-color: #e95a78;
		align-content: center;
		align-items: center;

		.image-all {
			opacity: 1;
			transition: .5s ease;
			backface-visibility: hidden;
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 0.9em;
		}
	}
}


.middle {

	transition: .5s ease;
	opacity: 0;
	position: relative;
	margin: auto;
	margin-left: 20px;
	margin-right: 20px;
	bottom: 50%;
	text-align: center;

}

.container-all:hover .image-all {
	opacity: 0.3;
}

.container-all:hover .middle {
	opacity: 1;
}

.text-all {
	background-color: #fc6082;
	color: white;
	font-size: 18px;
	font-family: Arial, Helvetica, sans-serif;

}

/* - - - - - - - - - - - - - */
/* -  Mobile Optimization  - */
/* - - - - - - - - - - - - - */

@media screen and (max-width: 827px) {

	/////////// Standard Stuff ////////////
	.landing {
		min-height: 100vh;

		.content {
			padding: 0 30px;
			box-sizing: border-box;
			width: 100%;

			.lms-texture {
				display: none;
			}
		}

		h1 {
			font-size: 45px;
		}

		p {
			line-height: 26px;
		}
	}

	#team_title {
		text-align: center;
	}


	.contact {
		padding: 100px 0;

		.lms-content-frame {
			flex-direction: column;
		}

		.email-link {
			img {
				height: 18px;
				width: 18px;
				margin: 0 15px 0 0;
			}
		}

		.contact-form {
			padding: 30px;
			margin: 30px 0 0 auto;
			width: 100%;
		}

	}

	.lumos-team-slider {

		.content {
			width: 20%;
			height: 80%;
			margin: 10px;
			padding: 15px 15px 30px;
			background-color: white;
			border: 1px solid $border-grey;
			box-shadow: 0 0 6px lightgray;
			border-radius: 20px;

			div {
				width: 100%;
				height: 100%;

				div {
					display: flex;
					flex-direction: column;
				}


				// img{
				//   border-radius: 15px;
				//   display: inline-block;
				//   position:relative;
				//   max-width:20%;
				//   max-height: 80%;
				//   margin:5px;
				//   border-color:#fc6082;
				//   border-style:solid;
				// }

				div {
					width: 100%;

					#aboutmediv {
						max-width: 100%;
					}

					.information {
						display: flex;
						max-width: 20%;
						max-height: 80%;
						margin: 5px;
						flex-direction: column;
						border-radius: 15px;
						background: #fff9fb;
						border-color: #fc6082;
						border-style: solid;
						border-width: 2px;

						.infotitle {
							margin-left: 10px;
							margin-top: 5px;
						}

						img {
							border-radius: 13px;
							height: 100%;
							max-width: 100%;

						}
					}

					.title-right {
						margin-top: 15px;
						margin-left: 12px;
						font-family: $avenir;
						font-size: 17px;
						width: 85%;
					}


				}
			}
		}

		.person {
			font-size: 24px;
			font-family: $avenir;
			padding: 0 0 5px 0;

			.lastName {
				color: $lumos-pink;
			}

		}

	}


}

/* - - - - - - - - - - - - - - - - -  */
/* -  Smaller Mobile Optimization  - */
/* - - - - - - - - - - - - - - - - -*/

@media screen and (max-width: 550px) {
	.header_image_container {
		.header_image {
			width: 100%;
			height: auto;
		}
	}

	.chart {
		width: auto;
	}

	#apex-container1 {
		width: auto;
	}

	#apex-container2 {
		width: auto;
	}

	#team_description_container {
		width: auto;
	}

	.chart {
		width: auto;
	}

	.splide__arrow svg {
		width: 20px;
		height: 20px;
	}

	#team_description {
		font-size: 14px;
	}

}
</style>
